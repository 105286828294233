<template>
  <div class="gmb-list">
    <screen-header
      :title="
        $tc('gmb.list.title', accountsPagination.nbResults, {
          brand: currentClient.name,
          count: accountsPagination.nbResults,
        })
      "
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :isBeta="true"
    />
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <div class="gmb-list__list">
              <ui-data-list
                :items="accountsMobile"
                :is-loading="isLoading"
                :is-scrolling="isScrolling"
                :no-more-data="noMoreData"
                :pagination="accountsPagination"
                infinite-scroll
                @loadItems="loadAccounts({ ...$event, page: accountsPagination.page + 1 }, true, true)"
                @click="goToAccount({ row: $event })"
              >
                <template v-slot:skeleton>
                  <div class="gmb-list__list__content">
                    <skeleton-line height="18px" width="80%" />
                    <skeleton-line height="15px" width="50%" margin-top="3px" />
                  </div>
                  <skeleton-icon margin-left="16px" />
                </template>
                <template v-slot:item="slotProps">
                  <div class="gmb-list__list__content">
                    <div class="gmb-list__list__content__name">
                      {{ slotProps.item.accountName }}
                    </div>
                    <address class="gmb-list__list__content__line">
                      {{ slotProps.item.role }} ({{ slotProps.item.type }})
                    </address>
                    <address class="gmb-list__list__content__line">
                      {{ slotProps.item.total_locations }}
                      {{ $tc('common.label.locations', slotProps.item.total_locations) }}
                    </address>
                  </div>
                  <span class="gmb-list__list__status">
                    {{ slotProps.item.state_status }}
                  </span>
                </template>
              </ui-data-list>
            </div>
            <div class="gmb-list__table">
              <ui-data-table
                :columns="columns"
                :rows="accounts"
                :total-rows="totalRows"
                :is-loading="isLoading || isUpdating"
                :pagination="accountsPagination"
                @loadItems="loadAccounts($event, false, true)"
                @onCellClick="goToAccount"
              >
                <template slot="data-table-row" slot-scope="props">
                  <router-link
                    class="gmb-list__table__link"
                    :to="{ name: 'AccountsId', params: { name: props.row['name'] } }"
                  />
                  <span v-if="props.column.field === 'accountName'">
                    {{ props.row['accountName'] }}
                  </span>
                  <span v-if="props.column.field === 'role'">
                    {{ props.row['role'] }}
                  </span>
                  <span v-if="props.column.field === 'type'">
                    {{ props.row['type'] }}
                  </span>
                  <span v-if="props.column.field === 'total_locations'">
                    {{ props.row['total_locations'] }}
                  </span>
                  <span v-if="props.column.field === 'state_status'">
                    {{ props.row['state_status'] }}
                  </span>
                  <span v-if="props.column.field === 'action'">
                    <router-link :to="{ name: 'LocationsGmb', params: { name: props.row['name'] } }">
                      <ui-button
                        class="gmb-list__table__button"
                        button="primary"
                        variant="influence"
                        :label="$t('gmb.list.button.seeLocations')"
                      />
                    </router-link>
                  </span>
                </template>
              </ui-data-table>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiButton from '@/components/UI/Button.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { onScroll } from '@/mixins/scroll.mixin'

export default {
  name: 'LocationsList',
  components: {
    UiDataTable,
    UiDataList,
    UiButton,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
  },
  mixins: [onScroll],
  data() {
    return {
      isLoading: false,
      isScrolling: false,
      isUpdating: false,
      noMoreData: false,
      totalRows: 0,
    }
  },
  mounted() {
    this.loadAccounts(null, true)
  },
  watch: {
    canRefreshData: {
      handler() {
        if (this.canRefreshData && !this.isScrolling && !this.noMoreData) {
          this.isScrolling = true
          this.loadAccounts({ page: this.accountsPagination.page + 1 })
        }
      },
    },
  },
  computed: {
    ...mapState({
      accounts: state => state.gmb.accounts,
      accountsMobile: state => state.gmb.accountsMobile,
      accountsPagination: state => state.gmb.accountsPagination,
      currentClient: state => state.client.currentClient,
    }),
    noData() {
      return this.isLoading || this.noMoreData
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('gmb.list.breadcrumb'),
          route: {
            name: 'Accounts',
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('gmb.list.column.accountName'),
          field: 'accountName',
        },
        {
          label: this.$t('gmb.list.column.role'),
          field: 'role',
        },
        {
          label: this.$t('gmb.list.column.type'),
          field: 'type',
        },
        {
          label: this.$t('gmb.list.column.total_locations'),
          field: 'total_locations',
        },
        {
          label: this.$t('gmb.list.column.state_status'),
          field: 'state_status',
        },
        {
          label: this.$t('gmb.list.column.action'),
          field: 'action',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getAccounts: 'gmb/getAccounts',
    }),
    goToAccount(e) {
      this.$router.push({ name: 'AccountsId', params: { name: e.row.name } })
    },
    loadAccounts(tableParams = null, resetAccounts = false, updating = false) {
      if (updating) {
        this.isUpdating = true
      } else {
        this.isLoading = true
      }
      this.getAccounts({ tableParams, resetAccounts })
        .then(() => {
          this.totalRows = this.accountsPagination.nbResults
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
        .catch(() => {
          this.noMoreData = true
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.gmb-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }

    &__content {
      flex: 1;

      &__name {
        font-weight: 600;
      }

      &__line {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }

    &__status {
      margin-left: $gutter-mobile;
    }
  }

  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }
    &__link {
      @include full-link;
    }
    &__button {
      position: relative;
      z-index: 3;
    }
  }
}
</style>
